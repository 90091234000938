import React from 'react'
import { Paper, Grid, Typography, Box, Button } from '@material-ui/core'
import AppLogo from '../../../static/images/marco-logo.svg'

import useStyles from './style'
import { Link } from 'gatsby'

const Footer = props => {
  const classes = useStyles()
  let { heading, sosmed, copyright } = props
  if (!heading) {
    heading = "It's More than a Haircut"
  }
  if (!sosmed) {
    sosmed = []
    sosmed.push({
      text: 'Facebook',
      link: 'https://www.facebook.com/search/top?q=marco%27s%20chopshop',
    })
    sosmed.push({ text: 'Twitter', link: 'https://twitter.com/MarcosChopshop' })
    sosmed.push({
      text: 'Instagram',
      link: 'https://www.instagram.com/marcoschopshop/',
    })
  }
  return (
    <Paper className={classes.root}>
      <Box display="flex" flexDirection="column">
        <Box mx="auto" textAlign="center" mb="60px">
          <img
            src={AppLogo}
            alt="Marco Chopshop"
            className={classes.mainLogo}
          />
        </Box>
        <Box mb="60px" textAlign="center">
          <Typography variant="h4" className={classes.heading}>
            {heading}
          </Typography>
        </Box>
        <Box
          className={classes.socialMedia}
          display="flex"
          justifyContent="center"
          mb="32px"
        >
          <Typography variant="body2" className={classes.socialMediaLink}>
            <Link to={sosmed[0].link}>{sosmed[0].text}</Link>
          </Typography>
          <Typography variant="body2" className={classes.socialMediaLink}>
            <Link to={sosmed[1].link}>{sosmed[1].text}</Link>
          </Typography>
          <Typography variant="body2" className={classes.socialMediaLink}>
            <Link to={sosmed[2].link}>{sosmed[2].text}</Link>
          </Typography>
        </Box>
        <Box textAlign="center">
          <Typography className={classes.copyRights}>
            {copyright
              ? copyright
              : '2020 Marco’s Chopshop Inc. All Rights Reserved'}
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default Footer
