import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = '100vw'
const appBarHeight = 64
const menuItemMargin = 32
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.up('md')]: {
      paddingTop: '56px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 15,
    },
  },
  /* Resposive Drawer Styling */
  drawer: {},
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    margin: '0 10px',
    minHeight: appBarHeight,
    maxHeight: appBarHeight,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: '20px',
    background: '#000',
  },
  drawerMenuLink: {
    textDecoration: 'none',
    color: '#FFF',
  },
  borderBottom: {
    borderBottom: '1px solid #333',
  },
  closeButton: {
    color: '#FFF',
  },

  /* Appbar and Toolbar */
  appBar: {
    [theme.breakpoints.down('sm')]: {
      // paddingTop: 15,
      // background:'#000',
      // minHeight:'93px',
      // position:'fixed'
    },
    minHeight: appBarHeight,
    maxHeight: appBarHeight,
    // position: "relative",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    zIndex: theme.zIndex.drawer + 1,
  },
  toolBar: {
    [theme.breakpoints.up('md')]: {
      width: '90%',
    },
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  menuButton: {
    color: '#FFF',
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuSection: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '33%',
    [theme.breakpoints.down('sm')]: {
      flexBasis: 0,
      display: 'none',
    },
  },
  menuLink: {
    textDecoration: 'none',
    color: '#FFF',
    cursor: 'pointer',
  },
  leftMenuItem: {
    marginRight: menuItemMargin,
  },
  rightMenuItem: {
    marginLeft: menuItemMargin,
  },
  logoWrapper: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      visibility: 'visible',
    },
  },
  mainLogo: {
    position: 'relative',
    display: 'inline-block',
    overflow: 'hidden',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 90.26,
      height: 44.24,
      marginTop: '15px',
    },
    width: 130.58,
    height: 64,
  },
  topNavigationGutters: {
    minHeight: 80,
    background: 'red',
  },
}))

export default useStyles
