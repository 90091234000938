import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    background: '#000',
    padding: '100px 5px 80px 5px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
    color: '#FFF',
  },
  mainLogo: {
    width: '115px',
  },
  heading: {
    fontSize: '48px',
    fontFamily: 'roosevelt02',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  socialMediaLink: {
    minWidth: '20%',
    fontSize: '16px',
    // marginRight: "32px",
    textAlign: 'center',
    '&& > a': {
      color: '#FFF',
      textDecoration: 'none',
    },
  },
  copyRights: {
    fontSize: '16px',
  },
}))

export default useStyles
