import React from 'react'
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@material-ui/core'
import Helmet from 'react-helmet'
import CssBaseline from '@material-ui/core/CssBaseline'
import '../../static/css/main.css'

const Layout = props => {
  const { children } = props
  let theme = createTheme({
    palette: {
      primary: { 500: '#D28F00' }, // custom color in hex
    },
    typography: {
      fontFamily: ['inter-regular'],
    },
  })
  theme = responsiveFontSizes(theme)

  return (
    <>
      <ThemeProvider theme={theme}>
        <Helmet>
          <meta
            name="google-site-verification"
            content="NEo7RWZofX1Bmjl6LC4xkilpBvUM93ofchx6OHFOfUY"
          />
        </Helmet>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </>
  )
}

export default Layout
