import React, { useState, cloneElement } from 'react'
import { useLocation } from '@reach/router'
import propTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { Link as AnchorLink } from 'react-scroll'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Hidden,
  Button,
  Drawer,
  Slide,
  useScrollTrigger,
} from '@material-ui/core'
import useStyles from './style'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import AppLogo from '../../../static/images/marco-logo.svg'

export const HideOnScroll = ({ children, window }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  })

  return cloneElement(children, {
    style: {
      backgroundColor: trigger ? '#000' : 'transparent',
      color: trigger ? 'white' : 'black',
      transition: trigger ? '0.3s' : '0.5s',
      boxShadow: 'none',
      padding: '45px 0px',
    },
  })
}

const Navbar = props => {
  const classes = useStyles()
  const [openDrawer, setOpenDrawer] = useState(false)
  const toggleDrawer = () => setOpenDrawer(!openDrawer)

  const currentLocation = useLocation().pathname
  // console.log('props.location', location.pathname)

  const menus = [
    {
      label: 'About',
      position: 'left',
      isScrolling: true,
      href: 'about',
      isDrawer: true,
    },
    {
      label: 'Gallery',
      position: 'left',
      isScrolling: true,
      href: 'gallery',
      isDrawer: true,
    },
    /*{
      label: "Testimonials",
      position: "left",
      isScrolling: true,
      href: "testimonials",
      isDrawer: true,
    },*/
    {
      label: 'Pricelist',
      position: 'left',
      isScrolling: true,
      href: 'https://marcoschopshopnearme.crd.co/',
      isDrawer: true,
    },
    {
      label: 'Stores',
      position: 'right',
      isScrolling: false,
      href: '/stores',
      isDrawer: false,
    },
    {
      label: 'Services',
      position: 'right',
      isScrolling: false,
      href: '/services',
      isDrawer: false,
    },
  ]

  const drawer = (
    <Box role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
      <div className={classes.drawerHeader}>
        <Box display="flex">
          <Box>
            <IconButton onClick={toggleDrawer}>
              <CloseIcon className={classes.closeButton} />
            </IconButton>
          </Box>
          <Box className={classes.logoWrapper} textAlign="center">
            <img
              src={AppLogo}
              alt="Marco Chopshop"
              className={classes.mainLogo}
            />
          </Box>
        </Box>
      </div>
      <List>
        {/* About Menu */}
        {currentLocation === '/services' || currentLocation === '/stores' ? (
          <Link to="/#about" className={classes.drawerMenuLink}>
            <ListItem button className={classes.borderBottom}>
              <ListItemText primary="About" />
            </ListItem>
          </Link>
        ) : (
          <AnchorLink
            className={classes.drawerMenuLink}
            to="about"
            smooth={true}
            duration={500}
            onClick={toggleDrawer}
            spy={true}
          >
            <ListItem button className={classes.borderBottom}>
              <ListItemText primary="About" />
            </ListItem>
          </AnchorLink>
        )}
        {/* Gallery Menu */}
        {currentLocation === '/services' || currentLocation === '/stores' ? (
          <Link to="/#gallery" className={classes.drawerMenuLink}>
            <ListItem button className={classes.borderBottom}>
              <ListItemText primary="Gallery" />
            </ListItem>
          </Link>
        ) : (
          <AnchorLink
            className={classes.drawerMenuLink}
            to="gallery"
            smooth={true}
            duration={500}
            onClick={toggleDrawer}
            spy={true}
          >
            <ListItem button className={classes.borderBottom}>
              <ListItemText primary="Gallery" />
            </ListItem>
          </AnchorLink>
        )}
        {/* Testimonials Menu */}
        {/*currentLocation === "/services" || currentLocation === "/stores" ? (
          <Link to="/#testimonials" className={classes.drawerMenuLink}>
            <ListItem button className={classes.borderBottom}>
              <ListItemText primary="Testimonials" />
            </ListItem>
          </Link>
        ) : (
          <AnchorLink
            className={classes.drawerMenuLink}
            to="testimonials"
            smooth={true}
            duration={500}
            onClick={toggleDrawer}
            spy={true}
          >
            <ListItem button className={classes.borderBottom}>
              <ListItemText primary="Testimonials" />
            </ListItem>
          </AnchorLink>
        )*/}

        <Link
          to="https://marcoschopshopnearme.crd.co/"
          className={classes.drawerMenuLink}
        >
          <ListItem button className={classes.borderBottom}>
            <ListItemText primary="Pricelist" />
          </ListItem>
        </Link>

        <Link to="/stores" className={classes.drawerMenuLink}>
          <ListItem button className={classes.borderBottom}>
            <ListItemText primary="Stores" />
          </ListItem>
        </Link>

        <Link to="/services" className={classes.drawerMenuLink}>
          <ListItem button>
            <ListItemText primary="Services" />
          </ListItem>
        </Link>

        {/* Menu Loops: Nanti aja */}
        {/* {menus.map((menu, index) =>
          menu.isScrolling ? (
            <AnchorLink
              key={index}
              className={classes.drawerMenuLink}
              activeClass="active"
              to={menu.href}
              smooth={true}
              duration={500}
              onClick={toggleDrawer}
              // offset={125}
              spy={true}
            >
              <ListItem button>
                <ListItemText primary={menu.label} />
              </ListItem>
            </AnchorLink>
          ) : (
            <Link to={menu.href} className={classes.drawerMenuLink}>
              <ListItem button>
                <ListItemText primary={menu.label} />
              </ListItem>
            </Link>
          )
        )} */}
      </List>
    </Box>
  )

  return (
    <Box className={classes.root}>
      <HideOnScroll {...props}>
        <AppBar
          position="fixed"
          className={classes.appBar}
          colorTransparent
          elevation={0}
        // style={{ background: "red" }}
        >
          <Toolbar className={classes.toolBar}>
            <IconButton
              aria-label="Open Navigation Drawer"
              edge="start"
              onClick={toggleDrawer}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Box display="flex" alignItems="center" width="100%">
              {/* Left Menu Items*/}
              <Box className={classes.menuSection}>
                <Typography
                  variant="subtitle1"
                  className={classes.leftMenuItem}
                >
                  {currentLocation === '/services' ||
                    currentLocation === '/stores' ? (
                    <Link to="/#about" className={classes.menuLink}>
                      About
                    </Link>
                  ) : (
                    <AnchorLink
                      className={classes.menuLink}
                      to="about"
                      smooth={true}
                      duration={500}
                      spy={true}
                    >
                      About
                    </AnchorLink>
                  )}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.leftMenuItem}
                >
                  {currentLocation === '/services' ||
                    currentLocation === '/stores' ? (
                    <Link to="/#gallery" className={classes.menuLink}>
                      Gallery
                    </Link>
                  ) : (
                    <AnchorLink
                      className={classes.menuLink}
                      to="gallery"
                      smooth={true}
                      duration={500}
                      spy={true}
                    >
                      Gallery
                    </AnchorLink>
                  )}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.leftMenuItem}
                >
                  {/*currentLocation === "/services" ||
                  currentLocation === "/stores" ? (
                    <Link to="/#testimonials" className={classes.menuLink}>
                      Testimonials
                    </Link>
                  ) : (
                    <AnchorLink
                      className={classes.menuLink}
                      to="testimonials"
                      smooth={true}
                      duration={500}
                      spy={true}
                    >
                      Testimonials
                    </AnchorLink>
                  )*/}
                  <Link
                    to="https://marcoschopshopnearme.crd.co/"
                    className={classes.menuLink}
                  >
                    Pricelist
                  </Link>
                </Typography>
              </Box>

              {/* Centered Logo */}
              <Box className={classes.logoWrapper} textAlign="center">
                {/* TODO: Replace to gatsby link */}
                <a href="/">
                  <img
                    src={AppLogo}
                    alt="Marco Chopshop"
                    className={classes.mainLogo}
                  />
                </a>
              </Box>

              {/* Right Menu Items */}
              <Box className={classes.menuSection} justifyContent="flex-end">
                {/* <Typography
                  variant="subtitle1"
                  className={classes.rightMenuItem}
                >
                  <Link to="/blog" className={classes.menuLink}>
                    Blog
                  </Link>
                </Typography> */}
                <Typography
                  variant="subtitle1"
                  className={classes.rightMenuItem}
                >
                  <Link to="/stores" className={classes.menuLink}>
                    Stores
                  </Link>
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.rightMenuItem}
                >
                  <Link to="/services" className={classes.menuLink}>
                    Services
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>

      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            open={openDrawer}
            onClose={toggleDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </Box>
  )
}

export default Navbar

HideOnScroll.propTypes = {
  children: propTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: propTypes.func,
}
